<template>
  <div class="research">
    <img alt="image" src="../assets/research.png" class="hezhao">
    <content-box class="research-white">
      <div class="research-intro">
        <!--<div class="intro-title">{{ $t('research').intro.title }}</div>-->
        <!--<div class="line"></div>-->
        <p v-for="(item, index) in $t('research').intro.content" :key="index" class="research-content font-18">{{item}}</p>
      </div>
    </content-box>
    <div v-for="(bodyItem, index) in $t('research').others" :key="index" :class="['research-item-box', backColor(index)]">
      <content-box :class="backColor(index)">
        <div class="research-title">{{ bodyItem.title }}</div>
        <p v-for="(item, index) in bodyItem.content" :key="index" class="research-content">{{item}}</p>
      </content-box>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentBox from '@/components/ContentBox.vue'

export default {
  name: 'research',
  components: {
    ContentBox
  },
  data () {
    return {
      researches: this.$i18n.research || {intro: {}},
      activeIndex: 0
    }
  },
  methods: {
    changeTab (index) {
      this.activeIndex = index
    },
    backColor (index) {
      return index % 2 === 0 ? 'research-gray' : 'research-white'
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="less">
  .research {
    width: 100%;
    font-size: 0;
  }

  .hezhao {
    width: 100%;
  }

  .research-white {
    background: #fff;
    padding: 1.5rem 0;
  }

  .research-gray {
    background: #F9F9F9;
    padding: 1.5rem 0;
  }

  .research-intro {
    font-size: 0;
    letter-spacing: 0;
    text-align: center;

    .intro-title {
      font-size: 3rem;
      color: #484848;
    }

    .line {
      width: 5.8rem;
      height: 1px;
      border-bottom: 1px solid #484848;
      text-align: center;
      display: inline-block;
      margin: 0.8rem 0 2.5rem 0;
    }

    .research-content {
      font-size: 1.3rem;
      text-align: justify;
      text-indent: 2em;
      margin: 0.8rem;
      color: #717171;
    }

    .font-18 {
      font-size: 1.5rem;
      color: #484848;
    }
  }

  .research-item-box {
    padding: 0;

    .research-title {
      font-size: 1.5rem;
      font-weight: 600;
      color: #484848;
      text-align: left;
    }

    .research-content {
      font-size: 1.3rem;
      text-align: justify;
      text-indent: 2em;
      margin: 0.8rem;
      color: #717171;
    }
  }
</style>
