<template>
  <div class="xiuxianhuodong">
    <img alt="image" src="../assets/publish.png" id="banner" class="hezhao">
    <calender :calenders="calenders" id="calender" :isFixed="isFixd" @changeDate="changeDate"></calender>
    <content-box>
      <div v-for="year in activities" :key="year.title" :id="year.title">
        <div v-for="item in year.children" :key="item.title">
          <article-card :item="item"></article-card>
        </div>
      </div>
    </content-box>
  </div>
</template>

<script>
// @ is an alias to /src
import ArticleCard from '@/components/ArticleCard.vue'
import publication from '../data/publication'
import ContentBox from '@/components/ContentBox.vue'
import Calender from '@/components/Calender.vue'

export default {
  name: 'publication',
  components: {
    ArticleCard,
    ContentBox,
    Calender
  },
  data () {
    return {
      isFixd: false,
      activities: publication,
      activeIndex: 0
    }
  },
  computed: {
    calenders() {
      let obj = []
      this.activities.forEach(item => {
        obj.push(item.title)
      })
      return obj
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, false)
  },
  mounted () {
    // const initItem = this.currentPage * 10
    // this.news = news.slice(initItem - 10, initItem)
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll, false)
  },
  methods: {
    changeTab (index) {
      this.activeIndex = index
    },
    changeDate (val) {
      let heighImg = document.getElementById('banner').clientHeight
      let heighbanner = document.getElementById('calender').clientHeight
      let height = 0
      let i = 0
      while (this.calenders[i] !== val) {
        height += document.getElementById(this.calenders[i]).clientHeight
        i++
      }
      if (val === '2019') {
        height = height + heighImg
      } else {
        height = height + heighImg - heighbanner / 2
      }
      window.scrollTo(0, height)
    },
    handleScroll () {
      const heightTop = document.documentElement.scrollTop || document.body.scrollTop
      let heighImg = document.getElementById('banner').clientHeight
      if (heightTop > heighImg) {
        this.isFixd = true
      } else {
        this.isFixd = false
      }
    }
  }
}
</script>

<style scoped>
  .xiuxianhuodong {
    width: 100%;
    font-size: 0;
  }

  .hezhao {
    width: 100%;
  }
</style>
