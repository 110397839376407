<template>
  <div class="xiuxian-activity" @click="goDetail">
    <img v-lazy="item.img" alt="activity-photo" class="photo">
    <p class="title">{{item.title}}</p>
    <p class="time">{{item.time}}</p>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    goDetail () {
      this.$store.commit('activity', this.item)
      this.$router.push('/xiuxianhuodongdetail')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.xiuxian-activity {
  padding: 10px;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  vertical-align: top;
  cursor: pointer;
}

.photo {
  width: 100%;
}

.title {
  font-size: 1.5rem;
  color: #484848;
  margin: 30px 0 0 0;
}

.time {
  font-size: 16px;
  color: #717171;
  margin-top: 10px;
}
</style>
