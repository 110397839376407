<template>
  <div class="table-card">
    <div class="table-header">
      <div class="width-9">No.</div>
      <div class="width-15">Date</div>
      <div class="width-15">Lecturer</div>
      <div class="width-21">Topic</div>
      <div class="width-10">Download</div>
      <div class="width-26">Key points</div>
    </div>
    <div class="table-body">
      <div v-for="(item, index) in tableData" :key="index" :class="['row', {'back-yellow': index % 2 !== 0}]">
        <div class="width-9">{{item.index}}</div>
        <div class="width-15">{{item.date}}</div>
        <div class="width-15">{{item.lecturer}}</div>
        <div class="width-21">
          <div>
            <p v-for="topicItem in item.topic" class="item" :key="topicItem">{{topicItem}}</p>
          </div>
        </div>
        <div class="width-10">
          <div class="key-box">
            <p v-for="keyItem in item.Download" class="item" :key="keyItem">{{keyItem}}</p>
          </div>
        </div>
        <div class="width-26">
          <div class="key-box">
            <p v-for="keyItem in item.key" class="item" :key="keyItem">{{keyItem}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import tableData from '../../data/courseTable'
export default {
  name: 'HelloWorld',
  data () {
    return {
      tableData: tableData
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.table-card {
  border-radius: 10px;
  overflow: hidden;

  .table-header {
    background: #FFD500;
    font-size: 1.83rem;
    color: #484848;
    font-weight: 600;
    height: 7.5rem;
    line-height: 7.5rem;

    div {
      display: inline-block;
      box-sizing: border-box;
      text-align: center;
    }
  }

  .table-body {
    font-size: 1.5rem;
    color: #484848;

    .row {
      height: 14.1rem;
      line-height: 14.1rem;
      background: #fff;

      div {
        display: inline-block;
        box-sizing: border-box;
        text-align: center;
        vertical-align: middle;

        .item {
          line-height: 1.8rem;
          margin: 0;
        }

        .key-box {
          width: 100%;
          padding: 0 1.5rem 0 4rem;
          text-align: left;
        }
      }
    }

    .back-yellow {
      background: #FFFBEE;
    }
  }

  .width-9 {
    width: 9.25%;
    border-right: 1px solid #FFEB83;
  }

  .width-15 {
    width: 15.9%;
    border-right: 1px solid #FFEB83;
  }

  .width-21 {
    width: 21.8%;
    border-right: 1px solid #FFEB83;
  }

  .width-36 {
    width: 36.4%;
  }

  .width-26 {
    width: 26.5%;
  }

  .width-10 {
    width: 10.3%;
  }
}
</style>
