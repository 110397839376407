<template>
  <div class="reference">
    <p class="reference-title">{{item.title}}</p>
    <div class="reference-disc" v-for="(listItem, index) in item.content" :key="index">
      <div class="index">{{index}}、</div>
      <div class="reference-disc-content">
        <p :class="{'tight': tight }">{{listItem.title}}</p>
        <p v-for="childItem in listItem.children" :key="childItem">{{childItem}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import tableData from '../../data/courseTable'
export default {
  name: 'HelloWorld',
  data () {
    return {
      tableData: tableData
    }
  },
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    },
    tight: Boolean
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.reference {
  background: #FFFFFF;
  box-shadow: 0 0px 15px rgba(130,130,130,0.19);
  border-radius: 9px;
  text-align: center;
  padding: 0 1.6rem;
  box-sizing: border-box;
  height: 35rem;
  vertical-align: top;

  .reference-title {
    font-size: 1.83rem;
    font-weight: bold;
    color: #484848;
    letter-spacing: 0;
    text-align: center;
    margin: 4.1rem 0 1.6rem;
  }

  .reference-disc {
    font-size: 1.5rem;
    color: #484848;
    text-align: left;

    div {
      display: inline-block;
      vertical-align: top;

      p {
        margin: 0 0 0.8rem 0;
      }

      p.tight {
        margin: 0 0 0.5rem 0;
      }
    }

    .index {
      width: 3rem;
    }

    .reference-disc-content {
      width: 90%;
    }
  }
}
</style>
