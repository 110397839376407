<template>
  <div class="new-item">
    <div class="time">
      <p class="year">{{item.year}}</p>
      <p class="month">{{item.month}}</p>
    </div>
    <div class="line"></div>
    <div class="new-content">
      <p class="title" @click="goDetail">{{item.title}}</p>
      <div v-for="newItem in item.others" :key="newItem.title">
        <p v-if="newItem.title !== 'Link'" class="disc-text"><span class="disc-title">{{newItem.title}}:</span><span class="disc-content"> {{newItem.content}}</span></p>
        <p v-if="newItem.title === 'Link'" class="disc-text link"><span class="disc-title">Link:</span><a :href="newItem.url">{{newItem.content}}</a></p>
      </div>
    </div>
    <div class="img">
        <img :src="item.img" :alt="item.img">
    </div>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  methods: {
    goDetail () {
      this.$store.commit('newData', this.item)
      this.$router.push('/newdetail')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.new-item {
  display: flex;
  border-bottom: 1px solid #DEE3E6;
  padding: 4.16rem 0;
  cursor: pointer;

  .time {
    width: 8.3%;

    p {
      margin: 0 0 1.6rem 0;
    }

    .year {
      font-size: 2.16rem;
      font-weight: 500;
      color: #484848;
    }

    .month {
      font-size: 3rem;
      font-weight: 600;
      color: #484848;
    }
  }

  .line {
    width: 9.8%;
    height: 0.8rem;
    border-bottom: 2px solid #D8D8D8;
  }

  .new-content {
    width: 59.1%;
    padding: 0 2.5rem;
    box-sizing: border-box;

    .title {
      font-size: 1.83rem;
      color: #484848;
      margin: 0;
      font-weight: 500;
    }

    .disc-text {
      font-size: 1.5rem;
      color: #717171;
      margin: 1.33rem 0 0 0;

      .link {
        color: #080DBF;
      }

      .disc-title {
        font-weight: 500;
        margin-right: 1.2rem;
        width: 12%;
        display: inline-block;
        vertical-align: top;
      }

      .disc-content {
        width: 81%;
        display: inline-block;
      }
    }
  }

  .img {
    width: 16.6%;


    img {
        width: 100%;
    }
  }
}
</style>
