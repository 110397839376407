<template>
  <div class="resource-box">
    <div class="title-box">
      <div class="title">{{title}}</div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'resource',
  props: {
    title: String
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.resource-box {
  padding: 0 2.5rem 2.5rem 2.5rem;
  background: #fff;
  border-radius: 1.1rem;
  box-sizing: border-box;

  .title-box {
    width: 100%;
    height: 7.75rem;
    line-height: 7.75rem;
    border-bottom: 1px solid #DEE3E6;
    text-align: left;
    box-sizing: border-box;

    .title {
      display: inline-block;
      font-size: 2rem;
      font-weight: 600;
      color: #484848;
      border-bottom: 0.33rem solid #484848;
    }
  }
}
</style>
