<template>
  <div class="xiuxianhuodong">
    <img alt="image" src="../assets/album.png" id="banner" class="hezhao">
    <calender :calenders="$t('xiuxian')" :isFixed="isFixd" id="calender" @changeDate="changeTeamMember"></calender>
    <content-box>
      <div v-for="year in activities" :key="year.title" :ref="getRef(year.title)">
        <div class="title">{{year.title}}</div>
        <xiuxian-activity v-for="(item, index) in year.children" :class="['active-item', {'noo-margin': index % 4 === 0}]" :key="item.title" :item="item"></xiuxian-activity>
      </div>
    </content-box>
  </div>
</template>

<script>
// @ is an alias to /src
import XiuxianActivity from '@/components/xiuxian/XiuxianActivity.vue'
import XiuxianActivitie from '../data/xiuxianhuodong'
import Calender from '@/components/Calender.vue'
import ContentBox from '@/components/ContentBox.vue'

export default {
  name: 'xiuxianhuodong',
  components: {
    XiuxianActivity,
    ContentBox,
    Calender
  },
  data () {
    return {
      activities: XiuxianActivitie,
      calenders: ['2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '生日会'],
      isFixd: false,
    }
  },
  computed: {
    newsCount () {
      const obj = {}
      let height = 0
      for (let i in this.$refs) {
        if (!obj[i]) {
          obj[i] = height
        }
        height += this.$refs[i][0]
      }
      return obj
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, false)
  },
  mounted () {
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll, false)
  },
  methods: {
    changeTeamMember (val) {
      if (this.$refs[val]) {
        let heighImg = document.getElementById('banner').clientHeight
        let height = 0
        let keys = Object.keys(this.$refs)
        for (let i = keys.length - 1; i >=0; i--) {
          // 由于每个新闻的高度不一样，所以循环获取相加
          if (parseInt(val) < parseInt(keys[i]) || val.match('生日')) {
            height = (height + this.$refs[keys[i]][0].clientHeight)
          }
        }
        if (val === '2018') {
          window.scrollTo(0, height + heighImg)
        } else {
          window.scrollTo(0, height + heighImg - 40)
        }
      }
    },
    handleScroll () {
      const heightTop = document.documentElement.scrollTop || document.body.scrollTop
      let heighImg = document.getElementById('banner').clientHeight
      if (heightTop > heighImg) {
        this.isFixd = true
      } else {
        this.isFixd = false
      }
    },
    changeDate (val) {
      let heighImg = document.getElementById('banner').clientHeight
      let heighbanner = document.getElementById('calender').clientHeight
      let height = 0
      for (let i = 0; i < this.newsCount[val]; i++) {
        // 由于每个新闻的高度不一样，所以循环获取相加
        height = (height + document.getElementById('id' + i).offsetHeight)
      }
      if (val === '2019') {
        height = height + heighImg
      } else {
        height = height + heighImg - heighbanner
      }
      window.scrollTo(0, height)
    },
    getRef (title) {
      if (title.match('生日')) {
        return title
      } else {
        return title.slice(0, 4)
      }
    }
  }
}
</script>

<style scoped>
  .xiuxianhuodong {
    width: 100%;
    font-size: 0;
  }

  .hezhao {
    width: 100%;
  }

  .activities {
    background: #f5f5f5;
    padding-bottom: 68px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    min-width: 600px;
    text-align: left;
    overflow: hidden;
  }

  .title {
    height: 3.3rem;
    line-height: 3.3rem;
    border-left: 5px solid #FB4E0E;
    text-align: left;
    padding-left: 20px;
    font-size: 2.16rem;
    color: #484848;
    margin-top: 30px;
  }

  .active-item {
    width: calc(25% - 20px);
    max-width: 290px;
    display: inline-block;
    margin: 30px 20px 0 0;
  }

  .no-margin {
    margin-right: 0;
  }
</style>
