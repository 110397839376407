<template>
  <div class="team">
    <img alt="image" id="bannerTeam" src="../assets/team.png" class="hezhao">
    <content-box :class="['tab', {'fixed': isFixd}]" id="tabBar">
      <div class="team-tab">
        <div v-for="(item, index) in $t('team').tabData" :key="item"  :class="{'tab-active': activeIndex === index }" @click="changeTab(item, index)">{{item}}</div>
      </div>
    </content-box>
    <content-box class="team-content">
      <div v-if="!isOldMember">
        <div v-for="(leader) in $t('team').currentTeam.leaders" :ref="leader.simpTitle" :key="leader.title">
          <div class="title">
            <img :src="leader.img" alt="学位">
            {{leader.title}}
          </div>
          <leader-card v-for="leaderItem in leader.content" :key="leaderItem.name" :item="leaderItem" class="card"></leader-card>
        </div>
        <div v-for="(leader) in $t('team').currentTeam.leaders2" :ref="leader.simpTitle" :key="leader.title">
          <div class="title">
            <img :src="leader.img" alt="学位">
            {{leader.title}}
          </div>
          <leader-card2 v-for="leaderItem in leader.content" :key="leaderItem.name" :item="leaderItem" class="card"></leader-card2>
        </div>
        <div v-for="(member) in $t('team').currentTeam.member" :ref="member.simpTitle" :key="member.title">
          <div class="title">
            <img :src="member.img" alt="学位">
            {{member.title}}
          </div>
          <member-card v-for="(item, index) in member.content" :class="['active-item', {'noo-margin': index % 4 === 0}]" :key="item.title" :item="item"></member-card>
        </div>
      </div>
      <div v-if="isOldMember">
        <div v-for="(oldMember) in $t('team').oldTeam" :key="oldMember.title + 'old'">
          <div class="title">{{oldMember.title}}</div>
          <member-card v-for="(item, index) in oldMember.content" :class="['active-item', {'noo-margin': index % 4 === 0}]" :key="item.title" :item="item"></member-card>
        </div>
      </div>
    </content-box>
  </div>
</template>

<script>
// @ is an alias to /src
import MemberCard from '@/components/team/MemberCard.vue'
import ContentBox from '@/components/ContentBox.vue'
import LeaderCard from '@/components/team/LeaderCard.vue'
import LeaderCard2 from '@/components/team/LeaderCard2.vue'
export default {
  name: 'xiuxianhuodong',
  components: {
    MemberCard,
    ContentBox,
    LeaderCard,
    LeaderCard2
  },
  data () {
    return {
      activeIndex: 0,
      isFixd: false,
      isOldMember: false,
      heights: {}
    }
  },
  methods: {
    changeTab (val, index) {
      this.activeIndex = index
      if ((val === '前成员') || (val === 'alumni')) {
        this.isOldMember = true
        let heighImg = document.getElementById('bannerTeam').clientHeight
        window.scrollTo(0, heighImg)
      } else if (this.isOldMember) {
        this.isOldMember = false
      }
      this.$nextTick(() => {
        if (!this.isOldMember) {
          this.changeTeamMember(val)
        } else {}
      })
    },
    initHeight () {
      let obj = {}
      for (let i in this.$refs) {
        if (this.$refs[i].length) {
          obj[i] = this.$refs[i][0].offsetTop
        }
      }
      return obj
    },
    changeTeamMember (val) {
      this.heights = this.initHeight()
      if (this.$refs[val]) {
        let heighImg = document.getElementById('bannerTeam').clientHeight
        let tabBarHeight = document.getElementById('tabBar').clientHeight
        if (val === 'PI') {
          window.scrollTo(0, this.heights[val] - heighImg + tabBarHeight)
        } else {
          window.scrollTo(0, this.heights[val] - heighImg)
        }
      } else {
        this.$notify({
          message: `暂无${val}成员`,
          type: 'warning'
        });
      }
    },
    handleScroll () {
      const heightTop = document.documentElement.scrollTop || document.body.scrollTop
      if (heightTop > 400) {
        this.isFixd = true
      } else {
        this.isFixd = false
      }
    }
  },
  mounted () {
    this.heights = this.initHeight()
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll, false)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, false)
  }
}
</script>

<style scoped lang="less">
  .team {
    width: 100%;
    font-size: 0;

    .fixed {
      position: fixed;
      top: 10.6rem;
      z-index: 100;
    }

    .hezhao {
      width: 100%;
    }

    .tab {
      height: 8.1rem;
      line-height: 8.1rem;
      background: #fff;
      padding: 0;
      width: 100%;
    }

    .team-tab {
       width: 100%;
       background: #fff;
       text-align: center;
       display: flex;
       justify-content: space-around;

       div {
         flex: 1 0.5;
         font-size: 1.66rem;
         color: #949494;
         display: inline-block;
         cursor: pointer;
         text-align: center;
       }

       .tab-active {
         font-weight: 600;
         color: #000;
         border-bottom: 3px solid #000;
       }
     }

    .team-content {
      .team-title {
        font-size: 5rem;
        font-weight: 900;
        text-align: center;
        margin: 5rem 0 4rem 0;
      }

      .card {
        box-sizing: border-box;
        vertical-align: top;
      }

      .title {
        height: 3rem;
        line-height: 3rem;
        text-align: left;
        font-size: 3rem;
        color: #484848;
        margin: 4.1rem 0 0 0;

        img {
          vertical-align: middle;
          height: 3rem;
        }
      }
    }
  }

  .activities {
    background: #f5f5f5;
    padding-bottom: 68px;
  }

  .content {
    width: 80%;
    margin: 0 auto;
    max-width: 1200px;
    min-width: 600px;
    text-align: left;
    overflow: hidden;
  }

  .active-item {
    width: calc(25% - 20px);
    max-width: 290px;
    display: inline-block;
    margin: 30px 20px 0 0;
  }

  .no-margin {
    margin-right: 0;
  }
</style>
