<template>
  <div class="btn" @click="handleClick">{{title}}</div>
</template>
<script>
export default {
  name: 'resourceBtn',
  props: {
    title: String,
    url: String
  },
  methods: {
    handleClick () {
      window.open(this.url)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
  .btn {
    padding: 5px 10px;
    display: inline-block;
    background: #564EEE;
    border-radius: 1.5rem;
    font-size: 1.5rem;
    color: #FFFFFF;
    cursor: pointer;
  }
</style>
