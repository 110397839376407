<template>
  <div class="new-detail">
    <content-box>
      <div class="title-box">
        <p class="title">{{newItem.title}}</p>
        <p class="info"><span class="updator">{{newItem.updator}}</span> <span>{{newItem.date}}</span></p>
      </div>
      <div class="new-content">
        <p class="new-text">{{newItem.content.text}}</p>
        <div class="img-box">
          <img :src="newItem.content.img" :alt="newItem.title">
        </div>
        <p class="back" @click="back"><img src="../assets/news/back.png" alt="返回"> 返回</p>
      </div>
    </content-box>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentBox from '@/components/ContentBox.vue'
import { getScroll } from '../_util/scroll'

export default {
  name: 'xiuxianhuodongdetail',
  components: {
    ContentBox
  },
  data () {
    return {
    }
  },
  mounted () {
    const currentTop = getScroll(true)
    window.scrollTo(currentTop, 0)
  },
  computed: {
    newItem () {
      return this.$store.getters.newData
    }
  },
  methods: {
    back () {
      history.back()
    }
  }
}
</script>

<style scoped lang="less">
  .new-detail {
    width: 100%;
    font-size: 0;

    .title-box {
      background: #FFFFFF;
      border-radius: 9px;
      padding: 3rem;

      .title {
        font-size: 2.8rem;
        color: #484848;
        text-align: justify;
        font-weight: 600;
        margin: 0;
      }

      .info {
        font-size: 1.66rem;
        color: #9B9B9B;

        .updator {
          color: #080DBF;
          text-decoration: underline;
          margin: 2rem 3rem 0 0 ;
        }
      }
    }

    .new-content {
      background: #FFFFFF;
      border-radius: 9px;
      padding: 3rem;
      margin: 1.8rem 0;

      .new-text {
        font-size: 2rem;
        color: #484848;
        margin: 0;
      }

      .img-box {
        width: 100%;
        margin-top: 2rem;
        text-align: center;

        img {
          max-width: 100%;
        }
      }

      .back {
        font-size: 2rem;
        color: #080DBF;
        text-align: center;
        margin-top: 3rem;
        vertical-align: middle;
        cursor: pointer;

        img {
          width: 20px;
          margin-right: 10px;
          vertical-align: middle;
        }
      }
    }
  }
</style>
