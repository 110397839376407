<template>
  <div class="xiuxian-activity">
    <div class="photo">
      <img v-lazy="item.img" alt="activity-photo">
    </div>
    <p class="name">
      <span>{{item.name}}</span>
      <span class="pos">{{item.pos}}</span>
    </p>
    <p class="disc margin-bottom-1" v-if="item.date">{{item.date}}</p>
    <p class="disc margin-bottom-2" v-if="item.poser">{{item.poser}}</p>
    <div class="blank" v-if="!item.date && !item.poser"></div>
    <p class="disc" v-for="disc in item.disc" :key="disc">{{disc}}</p>
  </div>
</template>
<script>
export default {
  name: 'HelloWorld',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.xiuxian-activity {
  padding: 0;
  border-radius: 6px;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  vertical-align: top;
  height: 28rem;
}

.photo {
  width: 100%;
  max-height: 16.6rem;
  overflow: hidden;
}

.photo img {
  width: 100%;
}

.margin-bottom-1 {
  margin: 1rem 0!important;
}

.margin-bottom-2 {
  margin-bottom: 1.5rem!important;
}

.blank {
  height: 1.5rem;
}

.name {
  font-size: 1.5rem;
  color: #484848;
  margin: 30px 0 0 0;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.pos {
  font-size: 1.3rem;
  color: #717171;
  padding: 0 10px;
}

.disc {
  font-size: 1.16rem;
  color: #717171;
  margin: 0;
  padding: 0 10px;
}
</style>
