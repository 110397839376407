<template>
  <div class="leader-card">
    <img :src="item.img" class="avatar">
    <div class="info-box">
      <p class="name">{{item.name}}</p>
      <p class="pos">{{item.pos}}</p>
      <p v-for="disc in item.disc" :key="disc" class="disc">{{disc}}</p>
      <p class="email">{{item.email}}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LeaderCard',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.leader-card {
  text-align: center;
  display: flex;
  box-shadow: 0 5px 14px 0 rgba(213,213,213,0.50);
  background: #fff;
  margin-top: 2.5rem;

  .avatar {
    width: 25rem;
    height: 25rem;
  }
  
  .info-box {
    margin-left: 2.5rem;
    text-align: left;
    word-wrap: break-word;
    word-break: keep-all;
    white-space: wrap;
    position: relative;
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 4.5rem;
  }

  .name {
    margin: 2rem 0 1rem 0;
    font-size: 2.5rem;
    color: #484848;
    font-weight: bold;
  }

  .pos {
    font-size: 1.5rem;
    color: #9B9B9B;
    margin: 0 0 0.83rem 0;
  }

  .disc {
    font-size: 1.5rem;
    color: #717171;
    margin: 0;
  }

  .email {
    font-size: 1.83rem;
    color: #080DBF;
    margin: 0.83rem 0 0 0;
    font-weight: 500;
    position: absolute;
    bottom: 2rem;
  }

  .left {
    text-align: left;
  }
}
</style>
