<template>
  <div class="resource-box">
    <div class="img-box">
      <img :src="item.img" class="img" alt="">
    </div>
    <div class="resource-content">
      <el-tooltip class="item" content="Start Server" effect="light" placement="top-start">
        <p class="item-title"><a :href="item.server">{{item.title}}</a>
          <!--<resource-btn title="Start Server" class="resource-btn" :url="item.server"></resource-btn>-->
        </p>
      </el-tooltip>
      <p class="links" v-if="item.hrefs">
        <a :href="item.hrefs.link" v-if="item.hrefs.link">Link</a>
          <span class="text-line"></span>
        <a :href="item.hrefs.pdf">PDF</a>
      </p>
      <p class="disc" v-if="item.disc">{{item.disc}}</p>
      <p class="links" v-if="item.code">
        <a :href="item.code">Code</a>
      </p>
      

      <p class="links" v-if="item.datalink">
        Data Link: <a :href="item.datalink">{{item.dataid}}</a>
      </p>
      <p class="links" v-if="item.paperlink">
        Paper Link: <a :href="item.paperlink">{{item.papertitle}}</a>
      </p>
      <p class="links" v-if="item.processeddata">
        <a :href="item.processeddata">Processed icSHAPE scores</a>
      </p>


    </div>
  </div>
</template>
<script>
// import ResourceBtn from './ResourceBtn'
export default {
  name: 'resource',
  // components: {ResourceBtn},
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.resource-box {
  padding: 1.6rem;
  background: #F8F8F8;
  border-radius: 0.75rem;

  .img-box {
    display: inline-block;
    width: 20%;
    margin-right: 3.6%;
    vertical-align: top;

    .img {
      width: 100%;
    }
  }

  .resource-content {
    text-align: left;
    position: relative;
    display: inline-block;
    width: 70%;

    .item-title {
      display: inline-block;
      font-size: 1.83rem;
      font-weight: 500;
      color: #484848;
      margin: 0;
      cursor: pointer;

      .resource-btn {
        margin-left: 20px;
      }
    }

    .item-title:hover {
      color: #564EEE;
      text-decoration: underline;
    }

    .disc {
      font-size: 1.5rem;
      color: #717171;
      /*height: 4rem;*/
    }
  }

  .links {
    font-size: 1.5rem;

    a {
      display: inline-block;
      font-size: 1.5rem;
      color: #080DBF;
      font-weight: 500;
      text-decoration: none;
    }

    .text-line {
      display: inline-block;
      width: 1px;
      height: 1.25rem;
      background: #717171;
      margin: 0 0.83rem;
    }
  }
}
</style>
