<template>
  <div :class="['calender', {'fixed': isFixed}]">
    <div class="line"></div>
    <div class="calender-node" v-for="(item, index) in calenders" :key="item" :href="'#' + item" @click="changeDateTab(index)">
      <!--<p class="disc" v-if="index !== 1 && !active">to be continued </p>-->
      <p :class="['vintage', {'active': activeIndex === index}]">{{item}}</p>
      <span :class="['dot', {'active-dot': activeIndex === index}]"></span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  props: {
    calenders: {
      type: Array,
      default () {
        return []
      }
    },
    isFixed: Boolean,
    active: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeIndex: this.active ? 0 : 1
    }
  },
  methods: {
    changeDateTab (index) {
      if (this.active) {
        this.activeIndex = index
        this.$emit('changeDate', this.calenders[index])
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="less" scoped>
.calender {
  position: relative;
  width: 100%;
  background: #fff;
  overflow: hidden;
  padding: 2.3rem 0;

  .line {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid #DEE3E6;
    position: absolute;
    bottom: 2.5rem;
  }

  .calender-node {
    font-size: 2.16rem;
    color: #949494;
    margin: 0 0 -20px 0;
    width: 8%;
    display: inline-block;
    min-width: 100px;
    cursor: pointer;
  }

  .disc {
      font-size:1.1rem;
      color: #717171;
  }

  .vintage {
    margin: 0 0 10px 0;
  }

  .dot {
    display: inline-block;
    width: 14px;
    height: 14px;
    background: #D8D8D8;
    border-radius: 50%;
    margin-bottom: -5px;
    position: relative;
    z-index: 10;
  }

  .active {
    color: #000;
  }

  .active-dot {
    background: #000;
  }
}

.fixed {
  position: fixed;
  top: 10.6rem;
}
</style>
