<template>
  <div class="article">
    <img v-if="item.img" :src="item.img" class="intro-img" alt="">
    <div v-if="!item.img" class="intro-img" style="height: 10px;"></div>
    <div style="flex: 1;">
      <p class="title-box title">{{item.title}}</p>
      <div class="info-box">
        <div>
          <p class="disc-text"><span class="disc-title"></span> 
            <span>{{item.author}}</span>
            <!--<span v-for="fontItem in item.author" :style="{fontWeight: fontItem.bold ? 'bold' : 'normal'}">{{fontItem.name}}</span>
          </p>-->
          <p class="disc-text">
            <span class="disc-title"></span>
            <a :href="item.Link || '#'" class="disc-title disc-title-link"> {{item.periodical}}</a>
            <a class="disc-link" :href="item.Project" v-if="item.Project">(Project)</a>
            <a class="disc-link" :href="item.Server" v-if="item.Server">(Server)</a>
            <a class="disc-link" :href="item.Docs" v-if="item.Docs">(Docs)</a>
            <a class="disc-link" :href="item.Code" v-if="item.Code">(Code)</a>
            <a class="disc-link" :href="item.arXiv" v-if="item.arXiv">(arXiv)</a>
            <a class="disc-link" :href="item.PDF" v-if="item.PDF">(PDF)</a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ArticleCard',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.article {
  padding: 1.3rem 4rem;
  background: #fff;
  box-sizing: border-box;
  text-align: left;
  vertical-align: top;
  border-radius: 1.2rem;
  margin: 0.83rem 0 0 0;
  display: flex;
  align-items: center;
}

.info-box {
  display: flex;
  justify-content: space-between;
}

.intro-img {
  width: 15rem;
  margin-right: 3rem;
}

.title {
  font-size: 1.83rem;
  font-weight: 600;
  color: #484848;
  margin: 0;
  text-decoration: none;
}

.disc-title {
  font-weight: 500;
  margin-right: 0rem;
}

.disc-title-link {
  font-size: 16px;
  color: #717171;
  text-decoration: none;
  cursor: pointer;
}

.disc-title-link:hover {
  color: #080DBF;
}

.disc-text {
  font-size: 16px;
  color: #717171;
  margin: 1.33rem 0 0 0;
  display: flex;
}

.disc-link {
  display: inline-block;
  text-decoration: none;
  font-weight: 500;
  margin: 0 0 0 1rem;
  font-size: 1.83rem;
  color: #080DBF;
}
</style>
