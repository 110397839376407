<template>
  <div class="contact">
    <content-box>
      <div class="contact-info-box" id="left">
        <p class="info-title">{{$t('contact').title}}</p>
        <span class="line"></span>
        <div class="info-item" v-for="(info, index) in $t('contact').info" :key="index">
          <p v-for="item in info" :key="item">{{item}}</p>
        </div>
        <p class="sub-tile">{{$t('contact').links.title}}</p>
        <div class="links-box">
          <a v-for="link in $t('contact').links.content" :key="link.title" :href="link.url">{{link.title}}</a>
        </div>
      </div>
      <div class="map-box">
        <baidu-map class="bm-view" :center="center" :zoom="17" @ready="handler">
          <bm-marker :position="center" @click="infoWindowOpen">
            <bm-info-window :show="show" class="info-window" @close="infoWindowClose" @open="infoWindowOpen">
              <div class="lab-title">ZhangLab实验室A109</div>
              <p class="locate">地址： 清华大学生物医学馆A109</p>
              <p class="locate">ZhangLab实验室A109</p>
            </bm-info-window>
          </bm-marker>
        </baidu-map>
      </div>
    </content-box>
  </div>
</template>

<script>
  // @ is an alias to /src
  import ContentBox from '@/components/ContentBox.vue'

  export default {
    name: 'news',
    components: {
      ContentBox
    },
    data () {
      return {
        activeIndex: 0,
        center: {lng: 0, lat: 0},
        show: true
      }
    },
    mounted () {
      window.scrollTo(0, 0)
    },
    methods: {
      handler () {
        this.center.lng = 116.327049
        this.center.lat = 40.012483
      },
      infoWindowClose () {
        this.show = false
      },
      infoWindowOpen () {
        this.show = true
      }
    }
  }
</script>

<style scoped lang="less">
.contact {
  .contact-info-box {
    width: 48%;
    height: 62rem;
    background-image: radial-gradient(29% 93%, #262B50 19%, #181635 94%);
    box-shadow: 0 19px 32px 0 #AAA9BF;
    padding: 7rem 1rem 7rem 3rem;
    margin-right: 3%;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    color: #fff;

    .info-title {
      font-size: 2.5rem;
      padding: 0 0 2.5rem 0;
      margin: 0;
    }

    .line {
      display: inline-block;
      background: #FF9D9E;
      width: 8rem;
      height: 0.5rem;
      margin: 0;
    }

    .info-item {
      margin: 4rem 0 0 0 ;
      font-size: 1.5rem;

      p {
        margin: 0;
      }
    }

    .sub-tile {
      font-size: 1.83rem;
      margin: 4rem 0 0 0 ;
      font-weight: 500;
    }

    .links-box {

      a {
        margin: 1.25rem 3rem 0 0;
        color: #ADD2FF;
        font-size: 1.5rem;
      }
    }
  }

  .map-box {
    width: 48%;
    height: 62rem;
    display: inline-block;
    vertical-align: top;

    .bm-view {
      width: 100%;
      height: 100%;
    }

    .info-window {


      .lab-title {
        font-size: 2.5rem;
        font-weight: bold;
        padding:  1rem 0 0.7rem 0;
        border-bottom: 2px solid #484848;
        margin-bottom: 2rem;
      }

      .locate {
        font-size: 1.8rem;
        color: #484848;
        margin: 1rem 0 0 0;
      }
    }
  }
}
</style>
