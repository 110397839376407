import getRequestAnimationFrame from '../_util/getRequestAnimationFrame'

const reqAnimFrame = getRequestAnimationFrame()

const setScrollTop = (value) => {
  document.body.scrollTop = value
  document.documentElement.scrollTop = value
}

const easeInOutCubic = (t, b, c, d) => {
  const cc = c - b
  t /= d / 2
  if (t < 1) {
    return cc / 2 * t * t * t + b
  } else {
    return cc / 2 * ((t -= 2) * t * t + 2) + b
  }
}

export function scrollFromTo (from, to) {
  const startTime = Date.now()
  const frameFunc = () => {
    const timestamp = Date.now()
    const time = timestamp - startTime
    setScrollTop(easeInOutCubic(time, from, to, 450))
    if (time < 450) {
      reqAnimFrame(frameFunc)
    }
  }
  reqAnimFrame(frameFunc)
}

export function getScroll (top) {
  if (typeof window === 'undefined') {
    return 0
  }

  const prop = top ? 'pageYOffset' : 'pageXOffset'
  const method = top ? 'scrollTop' : 'scrollLeft'

  let ret = window[prop]
  // ie6,7,8 standard mode
  if (typeof ret !== 'number') {
    ret = window.document.documentElement[method]
  }

  return ret
}
