<template>
  <div class="xiuxianhuodong">
    <content-box>
      <div class="title-box">
        <div class="img-box1">
          <img v-lazy="activityItem.img" alt="">
        </div>
        <div class="info-box">
          <p class="title">{{activityItem.title}}</p>
          <p class="disc">{{activityItem.time}} <span>{{activityItem.children && activityItem.children.length}}张</span></p>
        </div>
        <p class="update">上传人： <span>Li PAN</span></p>
      </div>
      <waterfall :col='4' :gutterWidth="10" :data="activityItem.children || []">
        <template>
          <div class="cell-item" v-for="(item, index) in activityItem.children" :key="index">
            
            <div class="img-box">
              <a v-bind:href="item" target="_blank"><img v-lazy="item"/></a>
            </div>

          </div>
        </template>
      </waterfall>
    </content-box>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentBox from '@/components/ContentBox.vue'

export default {
  name: 'xiuxianhuodongdetail',
  components: {
    ContentBox
  },
  data () {
    return {
    }
  },
  computed: {
    activityItem () {
      return this.$store.getters.activity
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="less">
  .xiuxianhuodong {
    width: 100%;
    font-size: 0;

    .title-box {
      .img-box1 {
        width: 8.3rem;
        height: 8.3rem;
        overflow: hidden;
        display: inline-block;

        img {
          width: 100%;
        }
      }

      .info-box {
        margin-left: 2rem;
        display: inline-block;
        vertical-align: top;

        .title {
          font-size: 2.8rem;
          color: #2A2A2A;
          font-weight: bold;
          margin: 0 0 1.2rem 0;
        }

        .disc {
          font-size: 1.5rem;
          color: #717171;

          span {
            margin-left: 1.3rem;
          }
        }

        .update {
          float: right;
          font-size: 1.5rem;
          color: #717171;

          span {
            font-size: 1.5rem;
            color: #080000;
            text-decoration: underline;
          }
        }
      }
    }

    .detail-content {
      margin-top: 4rem;
    }

    .img-box {
      width: 96%;
      margin: 1rem 0;
      overflow: hidden;
      background: #fff;
      padding: 10px;
      box-sizing: border-box;

      img {
        width: 100%;
      }
    }

    .no-margin {
      margin-right: 0;
    }
  }
</style>
