<template>
  <div class="course">
    <img alt="image" id="bannerTeam" src="../assets/course.png" class="hezhao">
    <content-box class="calender-box">
      <calender :calenders="calenders" :active="false" style="text-align: center;"></calender>
    </content-box>
    <content-box>
      <p class="course-title">
        Bioinformatics and Systems Biology
        <span class="course-year">2020</span>
      </p>
      <p class="course-disc">This course is taught by Professor Qiangfeng Cliff Zhang, Professor Wei Zhang and Professor Xuerui Yang.<br/>
        Cliff mainly teaches the basics of bioinformatics, omics analysis, structural bioinformatics, systems biology,<br/>
        and the frontiers of bioinformatics and system biology. Wei Zhang mainly teaches the core concepts,<br>
        principles and methods in evolutionary genomics;Yang mainly teaches Gene regulatory network model for complex diseases</p>
      <p class="course-table-title">Course materials</p>
      <table-card ></table-card>
    </content-box>
    <div class="instructor-box">
      <img src="../assets/course/introductors.png" alt="">
      <div class="course-content">
        <instructor v-for="(item, index) in instructors" :key="item.name" :item="item" :class="['course-instru', {'no-margin': index === 2}]"></instructor>
      </div>
    </div>
    <p class="sub-title">Practice</p>
    <div class="machine-btn"><a href="http://olddriver.website/virtualmachine/" target="_blank" class="virtual-box">CentOS-7 Linux Virtual Machine</a></div>
    <p class="sub-title margin-top4">Reference books</p>
    <div class="reference-content">
      <reference-card :item="referenceBox[0]" class="card margin-right1"></reference-card>
      <reference-card :item="referenceBox[1]" :tight="true" class="card"></reference-card>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import ContentBox from '@/components/ContentBox.vue'
import Calender from '@/components/Calender.vue'
import TableCard from '@/components/course/TableCard.vue'
import Instructor from '@/components/course/Instructor.vue'
import ReferenceCard from '@/components/course/ReferenceCard.vue'

export default {
  name: 'course',
  components: {
    ContentBox,
    Calender,
    TableCard,
    Instructor,
    ReferenceCard
  },
  data () {
    return {
      calenders: ['2021', '2020', '2019'],
      instructors: [
        {
          pos: 'Organizer',
          img: require('../assets/course/zhangqiangfeng.jpg'),
          name: '张强锋',
          school: '清华大学'
        },
        {
          pos: 'Invited lecturer',
          img: require('../assets/course/zhangwei.jpg'),
          name: '张蔚',
          school: '北京大学'
        },
        {
          pos: 'Invited lecturer',
          img: require('../assets/course/yangxuerui.jpg'),
          name: '杨雪瑞',
          school: '清华大学'
        }
      ],
      referenceBox: [
        {
          title: 'Bioinformatics',
          content: [
            {title: 'Bioinformatics and Functional Genomics by Jonathan Pevsner'},
            {title: 'MIT course 6.047/6.878: computational biology: genomes, '},
            {title: 'networks, evolution'},
            {title: 'Beginning perl for bioinformatics, O’Reilly'},
            {title: '《生物信息学》（第2版）李霞，雷健波，李亦学等编'}
            ]
        },
        {
          title: 'Linux OS and programming languages',
          content: [
            {title: 'Linux：“鸟哥的Linux私房菜 - 基础学习篇”'},
            {
              title: 'Python的/的Perl ',
              children: ['（1）《笨办法学 Python》（《Learn Python The Hard Way》）', '（2）《Python编程入门》', '（3）《Beginning Perl for Bioinformatics》']
            },
            {
              title: 'R（or MATLAB）：',
              children: ['（1）Quick R (online)', '（2）《R语言实战》 (《R in action》)']
            }
          ]
        }
      ]
    }
  },
  mounted () {
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped lang="less">
  .course {
    width: 100%;
    font-size: 0;
    margin-bottom: 4.16rem;

    .hezhao {
      width: 100%;
    }

    .calender-box {
      width: 100%;
      background: #fff;
      padding: 0;
    }

    .course-title {
      font-size: 3.3rem;
      font-weight: 600;
      color: #000;
      margin: 4.16rem 0 0.83rem;
      text-align: center;

      .course-year {
        color: #080DBF;
        margin-left: 0.83rem;
      }
    }

    .course-disc {
      font-size: 1.5rem;
      color: #717171;
      text-align: center;
    }

    .course-table-title {
      font-size: 3rem;
      color: #484848;
      text-align: center;
      margin: 4.1rem 0 2.5rem;
    }

    .instructor-box {
      width: 100%;
      position: relative;

      .course-content {
        background: transparent;
        width: 100%;
        max-width: 1200px;
        min-width: 900px;
        overflow: hidden;
        position: absolute;
        bottom: -4.16rem;
        left: 50%;
        transform: translateX(-50%);

        .course-instru {
          width: 32%;
          display: inline-block;
          margin-right: 1%;
        }

        .no-margin {
          margin-right: 0;
        }
      }
    }

    .sub-title {
      font-size: 3rem;
      font-weight: 600;
      color: #000;
      margin: 8.4rem 0 2.5rem;
      text-align: center;
    }

    .machine-btn {
      background: #FFD500;
      box-shadow: 0 4px 15px 0 #FFD500;
      border-radius: 40px;
      font-size: 2.5rem;
      color: #3E3E3E;
      width: 46rem;
      height: 6.8rem;
      line-height: 6.8rem;
      margin: 0 auto;
    }

    .margin-top4 {
      margin-top: 4.16rem;
    }

    .margin-right1 {
      margin-right: 1.6rem;
    }

    .reference-content {
      background: #fff;
      width: 100%;
      margin: 0 auto;
      max-width: 1200px;
      min-width: 900px;
      overflow: hidden;
      padding: 15px 0;

      .card {
        width: 48%;
        display: inline-block;
      }
    }

    .virtual-box {
      text-decoration: none; color: white
    }
  }
</style>
