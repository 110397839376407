<template>
  <div class="instructor">
    <p class="instru-title">{{item.pos}}</p>
    <div class="img-box">
      <img :src="item.img" class="avatar">
    </div>
    <p class="name">{{item.name}}</p>
    <p class="school">{{item.school}}</p>
    <div class="instru-footer"></div>
  </div>
</template>
<script>
export default {
  name: 'instructor',
  props: {
    item: {
      type: Object,
      default () {
        return {}
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
.instructor {
  background: #FFFFFF;
  box-shadow: 0 0.5rem 1.75rem 0 rgba(130,130,130,0.19);
  border-radius: 9px;
  text-align: center;

  .instru-title {
    font-size: 1.83rem;
    color: #484848;
    letter-spacing: 0;
    font-weight: 500;
    text-align: center;
  }

  .img-box {
    width: 10.8rem;
    height: 10.8rem;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
  }

  .avatar {
    width: 100%;
  }

  .name {
    margin: 2.16rem;
    font-size: 2.12rem;
    color: #484848;
    font-weight: 500;
  }

  .school {
    font-size: 1.83rem;
    color: #717171;
    margin: 0 0 2.5rem 0;
  }

  .instru-footer {
    width: 32%;
    height: 1.25rem;
    background: #FFD500;
    border-radius: 0 0 9px 9px;
    position: absolute;
    bottom: 0;
  }
}
</style>
