<template>
  <div class="xiuxianhuodong">
    <img alt="image" id="bannerTeam" src="../assets/resources.png" class="hezhao">
    <content-box :class="['tab', {'fixed': isFixd}]" id="tabBar">
      <div class="team-tab">
        <div v-for="(item, index) in $t('resource')" :key="item"  :class="{'tab-active': activeIndex === index }" @click="changeTab(item, index)">{{item}}</div>
      </div>
    </content-box>
    <content-box>
      <div :ref="$t('resource')[0]">
        <resource-box :title="$t('resource')[0]" class="resource">
          <ResourceItem v-for="(item, index) in prePPI" :class="['item', {'no-margin': index % 2 !== 0}]" :item="item" :key="item.title"></ResourceItem>
        </resource-box>
      </div>
      <div :ref="$t('resource')[1]">
        <resource-box :title="$t('resource')[1]" class="resource">
          <ResourceItem v-for="(item, index) in protocol" :class="['item', {'no-margin': index % 2 !== 0}]" :item="item" :key="item.title"></ResourceItem>
        </resource-box>
        <!--<resource-box :title="$t('resource')[1]" class="protocol">-->
          <!--<div class="protocol-inner-box">-->
            <!--<img :src="protocol.img" alt="">-->
            <!--<div class="text-box">-->
              <!--<p v-for="(item, index) in protocol.links" :class="['text-item', {'no-margin': index === 2}]" :key="item.title">-->
                <!--<span>{{item.title}}</span>-->
                <!--<span class="text-right">-->
            <!--<a :href="item.hrefs.link">Link</a>-->
            <!--<span class="text-line"></span>-->
            <!--<a :href="item.hrefs.pdf">PDF</a>-->
          <!--</span>-->
              <!--</p>-->
            <!--</div>-->
          <!--</div>-->
        <!--</resource-box>-->
      </div>
      <div :ref="$t('resource')[2]">
        <resource-box :title="$t('resource')[2]" class="protocol">
          <ResourceItem v-for="(item, index) in published" :class="['item', {'no-margin': index % 2 !== 0}]" :item="item" :key="item.title"></ResourceItem>
          <!--<div class="protocol-inner-box">-->
          <!--<img :src="protocol.img" alt="">-->
          <!--<div class="text-box">-->
          <!--<p v-for="(item, index) in protocol.links" :class="['text-item', {'no-margin': index === 2}]" :key="item.title">-->
          <!--<span>{{item.title}}</span>-->
          <!--<span class="text-right">-->
          <!--<a :href="item.hrefs.link">Link</a>-->
          <!--<span class="text-line"></span>-->
          <!--<a :href="item.hrefs.pdf">PDF</a>-->
          <!--</span>-->
          <!--</p>-->
          <!--</div>-->
          <!--</div>-->
        </resource-box>
      </div>
      <div :ref="$t('resource')[3]">
        <resource-box :title="$t('resource')[3]" class="wiki">
          <div class="wiki-inner-box">
            <img src="../assets/resource/wiki.png"><br/>
            <div class="btn-box">
              <resource-btn title="zhanglab wiki" url="http://tsinghua.frpgz1.idcfengye.com/wiki/doku.php"></resource-btn>
            </div>
          </div>
        </resource-box>
      </div>
    </content-box>
  </div>
</template>

<script>
// @ is an alias to /src
import ResourceBox from '@/components/resource/ResourceBox.vue'
import ContentBox from '@/components/ContentBox.vue'
import ResourceItem from '@/components/resource/ResourceItem.vue'
import ResourceBtn from '@/components/resource/ResourceBtn.vue'
import publication from '../data/publication'

export default {
  name: 'resources',
  components: {
    ResourceBox,
    ResourceItem,
    ResourceBtn,
    ContentBox
  },
  data () {
    return {
      activities: publication,
      activeIndex: 0,
      isFixd: false,
      heights: {},
      prePPI: [
        {
          title: 'PrePPI',
          img: require('../assets/resource/preppi.png'),
          disc: 'An on-line database of predicted and experimentally determined protein-protein interactions.',
          server: 'https://bhapp.c2b2.columbia.edu/PrePPI/'
        },
        {
          title: 'PredUs',
          img: require('../assets/resource/predus.png'),
          disc: 'An on-line database of predicted and experimentally determined protein-protein interactions.',
          server: 'http://bhapp.c2b2.columbia.edu/PredUs/'
        },
        {
          title: 'RISE',
          img: require('../assets/resource/rise.png'),
          disc: 'A database of RNA interactome from sequencing experiments.',
          server: 'http://rise.life.tsinghua.edu.cn'
        },
        {
          title: 'VRmol',
          img: require('../assets/resource/vrmol.png'),
          disc: 'An integrative cloud-Based virtual reality system to explore macromolecular structure.',
          server: 'https://VRmol.net/',
          code: 'https://github.com/kuixu/VRmol'
        },
        {
          title: 'RASP',
          img: require('../assets/resource/RASP.png'),
          disc: 'An atlas of transcriptome-wide RNA secondary structure probing data',
          server: 'http://rasp.zhanglab.net/'
        },
        {
          title: 'PrismNet',
          img: require('../assets/resource/prismnet.png'),
          disc: 'A deep learning tool that integrates experimental in vivo RNA structure data and RBP binding data for predicting dynamic cellular protein-RNA interactions',
          server: 'http://prismnetweb.zhanglab.net/'
        },
        {
          title: 'SCALE',
          img: require('../assets/resource/201910.png'),
          disc: 'A deep learning tool for single-cell ATAC-seq analysis including visualization, clustering and imputation',
          server: 'https://github.com/jsxlei/SCALE'
        },
        {
          title: 'SCALEX',
          img: require('../assets/resource/202210.png'),
          disc: 'A deep learning tool for single-cell sequencing data integration',
          server: 'https://scalex.readthedocs.io/en/latest/tutorial/index.html'
        },
        {
          title: 'SPACE',
          img: require('../assets/resource/SPACE.png'),
          disc: 'A deep learning tool for spatial transcriptomics analysis via cell embedding',
          server: 'https://github.com/zhangqf-lab/SPACE'
        },
      ],
      protocol: [
        {
          title: 'icSHAPE',
          img: require('../assets/resource/protocol.png'),
          server: 'https://www.nature.com/articles/nprot.2016.011',
          hrefs: {
            link: 'https://www.nature.com/articles/nprot.2016.011',
            pdf: 'http://tsinghua.frpgz1.idcfengye.com/wp-content/uploads/papers/20160114_NatureProtocol.pdf'
          }
        },
        {
          title: 'PARIS',
          img: require('../assets/resource/protocol2.png'),
          server: 'https://link.springer.com/protocol/10.1007%2F978-1-4939-7213-5_4',
          hrefs: {
            link: 'https://link.springer.com/protocol/10.1007%2F978-1-4939-7213-5_4',
            pdf: 'http://tsinghua.frpgz1.idcfengye.com/wp-content/uploads/2018/12/2018_MethMolBiol.pdf'
          }
        },
        {
          title: 'RISE',
          img: require('../assets/resource/protocol3.png'),
          server: 'https://currentprotocols.onlinelibrary.wiley.com/doi/full/10.1002/cpbi.58',
          hrefs: {
            link: 'https://currentprotocols.onlinelibrary.wiley.com/doi/full/10.1002/cpbi.58',
            pdf: 'http://tsinghua.frpgz1.idcfengye.com/wp-content/uploads/2019/01/2018_CurrentProtocols.pdf'
          }
        }
      ],
      published: [
        {
          title: 'mES icSHAPE',
          server: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE64169',
          img: require('../assets/publition/EmptyPaper.png'),
          datalink: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE64169',
          dataid: 'GSE64169',
          paperlink: 'http://zhanglab.net/files/before_2016/20150318_Nature.pdf',
          papertitle: 'Structural imprints in vivo decode RNA regulatory mechanisms'
        },
        {
          title: 'mES/HEK293 PARIS, HEK293 icSHAPE',
          server: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE74353',
          img: require('../assets/publition/2016.png'),
          datalink: 'http://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE74353',
          dataid: 'GSE74353',
          paperlink: 'http://zhanglab.net/files/20160512_Cell.pdf',
          papertitle: 'RNA Duplex Map in Living Cells Reveals Higher-Order Transcriptome Structure'
        },
        {
          title: 'ZIKV icSHAPE, ZIKV PARIS',
          server: 'https://www.ebi.ac.uk/ena/data/view/PRJEB28648',
          img: require('../assets/publition/20182.png'),
          datalink: 'https://www.ebi.ac.uk/ena/data/view/PRJEB28648',
          dataid: 'PRJEB28648',
          paperlink: 'http://zhanglab.net/files/20181212_Cell_HOST&Microbe.pdf',
          papertitle: 'Integrative Analysis of Zika Virus Genome RNA Structure Reveals Critical Determinants of Viral Infectivity'
        },
        {
          title: 'HEK293/mES Ch/Np/Cy icSHAPE',
          server: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE117840',
          img: require('../assets/publition/201902.png'),
          datalink: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE117840',
          dataid: 'GSE117840',
          paperlink: 'http://zhanglab.net/files/20190207_NSMB.pdf',
          papertitle: 'RNA structure maps across mammalian cellular compartments',
          processeddata: 'http://zhanglab.net/files/PublishedData/NSMB2019.zip'
        },
        {
          title: 'Zebrafish icSHAPE',
          server: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE120724',
          img: require('../assets/publition/202005.png'),
          datalink: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE120724',
          dataid: 'GSE120724',
          paperlink: 'http://zhanglab.net/files/2020_GenomeBiology.pdf',
          papertitle: 'RNA structural dynamics regulate early embryogenesis through controlling transcriptome fate and function',
          processeddata: 'http://zhanglab.net/files/PublishedData/Zebrafish_icSHAPE.tar'
        },
        {
          title: 'SARS-CoV-2 icSHAPE and conserved structural elements',
          server: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE153984',
          img: require('../assets/publition/2021_Cell.png'),
          datalink: 'https://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE153984',
          dataid: 'GSE153984',
          paperlink: 'http://zhanglab.net/files/2021_Cell.pdf',
          papertitle: 'In vivo structural characterization of the SARS-CoV-2 RNA genome identifies host proteins vulnerable to repurposed drugs',
          processeddata: 'http://zhanglab.net/files/PublishedData/SARS2_icSHAPE.tar'
        }
      ]
    }
  },
  methods: {
    changeTab (val, index) {
      this.activeIndex = index
      this.heights = this.initHeight()
      if (this.$refs[val]) {
        let heighImg = document.getElementById('bannerTeam').clientHeight
        let tabBarHeight = document.getElementById('tabBar').clientHeight
        if (index === 0) {
          window.scrollTo(0, this.heights[val] - heighImg + tabBarHeight + 50)
        } else {
          window.scrollTo(0, this.heights[val] - heighImg + 50)
        }
      } else {
        this.$notify({
          message: `暂无${val}成员`,
          type: 'warning'
        });
      }
    },
    initHeight () {
      let obj = {}
      for (let i in this.$refs) {
        if (this.$refs[i]) {
          obj[i] = this.$refs[i].offsetTop
        }
      }
      console.log(obj)
      return obj
    },
    handleScroll () {
      const heightTop = document.documentElement.scrollTop || document.body.scrollTop
      if (heightTop > 400) {
        this.isFixd = true
      } else {
        this.isFixd = false
      }
    }
  },
  mounted () {
    this.heights = this.initHeight()
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll, false)
  }
}
</script>

<style scoped lang="less">
  .xiuxianhuodong {
    width: 100%;
    font-size: 0;
  }

  .fixed {
    position: fixed;
    top: 10.6rem;
    z-index: 100;
  }

  .hezhao {
    width: 100%;
  }

  .tab {
    height: 8.1rem;
    line-height: 8.1rem;
    background: #fff;
    padding: 0;
    width: 100%;
  }

  .team-tab {
    width: 100%;
    background: #fff;
    text-align: center;
    display: flex;
    justify-content: space-around;

    div {
      flex: 1 0.5;
      font-size: 1.66rem;
      color: #949494;
      display: inline-block;
      cursor: pointer;
      text-align: center;
    }

    .tab-active {
      font-weight: 600;
      color: #000;
      border-bottom: 3px solid #000;
    }
  }

  .item {
    display: inline-block;
    width: 100%;
    margin: 0.83rem 3.33% 0 0;
  }

  .no-margin {
    margin-right: 0;
  }

  .protocol {
    display: inline-block;
    width: 100%;
    margin: 0.83rem 1.25% 1.66rem 0;

    .protocol-inner-box {
      margin: 2.5rem 0 0 0;
      display: flex;
      justify-content: space-between;
      background: #F8F8F8;
      padding: 1.3rem;

      img {
        width: 27.5rem;
        border-radius: 10px;
        vertical-align: middle;
      }

      .text-box {
        width: 20rem;
        margin-left: 2rem;
        border-radius: 0.75rem;
        padding: 1.66rem;
        box-sizing: border-box;
        font-size: 1.5rem;
        color: #484848;
        text-align: left;
        vertical-align: middle;

        .text-item {
          margin: 0 0 1.66rem 0;
        }

        .no-margin {
          margin-bottom: 0;
        }

        .text-right {
          float: right;
        }

        a {
          display: inline-block;
          font-size: 1.5rem;
          color: #080DBF;
          font-weight: 500;
        }

        .text-line {
          display: inline-block;
          width: 1px;
          height: 1.25rem;
          background: #717171;
          margin: 0 0.83rem;
        }
      }
    }
  }

  .wiki {
    display: inline-block;
    width: 100%;
    height: 38.25rem;
    margin-top: 0.83rem;
    vertical-align: top;
    text-align: center;

    .wiki-inner-box {
      margin: 2.5rem 0 0 0;
      display: flex;
      justify-content: space-between;
      background: #F8F8F8;
      padding: 1.3rem;

      img {
        width: 27.5rem;
        border-radius: 10px;
        vertical-align: middle;
      }

      .btn-box {
        width: 20rem;
        text-align: center;
        align-self: center;
      }
    }
  }
</style>
