const tableData = [
  {
    index: 1,
    date: '5/25/2020',
    lecturer: 'Q.C. Zhang',
    topic: ['Introduction to Bioinformatics'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 2,
    date: '5/26/2020',
    lecturer: 'W. Zhang',
    topic: ['The core concepts, principles and methods in evolutionary genomics (Part 1)'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 3,
    date: '5/28/2020',
    lecturer: 'W. Zhang',
    topic: ['The core concepts, principles and methods in evolutionary genomics (Part 2)'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 4,
    date: '5/28/2020',
    lecturer: 'Q.C. Zhang',
    topic: ['Omics data analysis'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 5,
    date: '5/28/2020',
    lecturer: 'Q.C. Zhang',
    topic: ['Structural bioinformatics'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 6,
    date: '6/1/2020',
    lecturer: 'Q.C. Zhang',
    topic: ['Basics of Systems Biology'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 7,
    date: '6/2/2020',
    lecturer: 'Q.C. Zhang',
    topic: ['Frontiers of Bioinformatics and Systems Biology (Part 1)'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 8,
    date: '6/2/2020',
    lecturer: 'Q.C. Zhang',
    topic: ['Frontiers of Bioinformatics and Systems Biology (Part 2)'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 9,
    date: '6/4/2020',
    lecturer: 'X.R. Yang',
    topic: ['Gene regulation network model of complex diseases (Part 1)'],
    key: ['***'],
    Download: ['#']
  },
  {
    index: 10,
    date: '6/4/2020',
    lecturer: 'X.R. Yang',
    topic: ['Gene regulation network model of complex diseases (Part 2)'],
    key: ['***'],
    Download: ['#']
  }
]

export default tableData
