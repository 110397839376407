<template>
  <div class="xiuxianhuodong">
    <img alt="image" src="../assets/news.png" id="banner" class="hezhao">
    <calender :calenders="calenders" id="calender" :isFixed="isFixd" @changeDate="changeDate"></calender>
    <content-box class="back-white" id="content">
      <div v-for="(newItem, index) in news" :key="newItem.title" :id="'id' + index">
        <new-item :item="newItem"></new-item>
      </div>
    </content-box>
  </div>
</template>

<script>
// @ is an alias to /src
import NewItem from '@/components/news/NewItem.vue'
import news from '../data/news'
import Calender from '@/components/Calender.vue'
import ContentBox from '@/components/ContentBox.vue'

export default {
  name: 'news',
  components: {
    NewItem,
    ContentBox,
    Calender
  },
  data () {
    return {
      news: news,
      activeIndex: 0,
      calenders: ['2024', '2023', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015'],
      isFixd: false,
      total: news.length,
      currentPage: this.$store.getters.currentPage
    }
  },
  computed: {
    newsCount () {
      const obj = {}
      let count = 0
      for (let i = 0; i < this.news.length; i++) {
        if (!obj[this.news[i].year]) {
          obj[this.news[i].year] = count
        }
        count++
      }
      return obj
    }
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, false)
  },
  mounted () {
    // const initItem = this.currentPage * 10
    // this.news = news.slice(initItem - 10, initItem)
    window.scrollTo(0, 0)
    window.addEventListener('scroll', this.handleScroll, false)
  },
  methods: {
    changeTab (index) {
      this.activeIndex = index
    },
    handleScroll () {
      const heightTop = document.documentElement.scrollTop || document.body.scrollTop
      if (heightTop > 400) {
        this.isFixd = true
      } else {
        this.isFixd = false
      }
    },
    changeDate (val) {
      let heighImg = document.getElementById('banner').clientHeight
      console.log(heighImg)
      let heighbanner = document.getElementById('calender').clientHeight
      let height = 0
      for (let i = 0; i < this.newsCount[val]; i++) {
        // 由于每个新闻的高度不一样，所以循环获取相加
        height = (height + document.getElementById('id' + i).offsetHeight)
      }
      if (val === '2019') {
        height = height + heighImg
      } else {
        height = height + heighImg - heighbanner
      }
      window.scrollTo(0, height)
    }
  }
}
</script>

<style scoped>
  .xiuxianhuodong {
    width: 100%;
    font-size: 0;
  }

  .hezhao {
    width: 100%;
  }

  .back-white {
    background: #fff;
  }

  .pages {
    text-align: right;
    margin-top: 6.6rem;
  }
</style>
